<template>
  <div class="home">
    <div class="home-bnddr">
        <!-- 轮播与条目 -->
        <div class="honme-benerr-list">
          <div class="honme-benerr-list-img">
            <el-carousel trigger="click" height="590px" @change="bannerchange">
              <el-carousel-item v-for="(item,index) in bannerlist" :key="index">
                <img class="banner-img" :src="item?.['url'][0]?.['url']" v-if="item?.['url'][0]?.['type'] =='image' && item.typelist==true" alt="" @click="gotopage(item.link)">
                
                <video class="banner-video" :key="videokey" :src="item?.['url'][0]?.['url']" controls="controls"  v-if="item?.['url'][0]?.['type'] =='video'"></video>
                
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="honme-benerr-list-tiaomu">
            <div class="honme-benerr-list-tiaomu-title">
              <div class="honme-benerr-title-baioti">
                <div class="biduhuize">必读规则</div>
                <div @click="toguize()"><span style="margin-right: 5px;color:#275AF5;font-size: 15px;">更多</span><img style="width: 10px;height: 10px;" src="../assets/image/lanjiantou.png"/></div>
              </div>
              
            </div>
            <div class="home-biduguize" v-for="(item,index) in reild" :key="index">
                <div class="home-biduguize-list" @click="bidugz(item)">{{item.title}}</div>
            </div>
          </div>
        </div>
        <!-- 推荐课程 -->
        <div class="tuijian-list-kecheng">
          <div class="tuijian-kecheng-tuijiankc">
            <div class="biduhuize">推荐课程</div>
            <div><span style="margin-right: 5px;color:#275AF5;font-size: 15px;"  @click="tokecehgn()">更多课程</span><img style="width: 10px;height: 10px;" src="../assets/image/lanjiantou.png"/></div>
          </div>
          <div class="tuijian-kecheng-xuxilist">
            <div class="tuijian-xuxilist-list" :class="(index + 1) % 4  == 0 ? 'mele':''" v-for="(item,index) in keclist" :key="index">
              <div class="tuijian-xuanlist-img"><img :src="item.img"/></div>
              <div class="tuijian-xuanlist-biaoti">{{item.title}}</div>
              <div class="kanshu-anniu-lost">
                <div class="tuijian-renshu-zong">
                  <div><img style=" width: 14px;height: 14px;margin-right: 5px;" src="../assets/image/huiyan.png"/></div>
                  <div>{{item.browse_number}}</div>
                </div>
                <div class="kanshu-anniu-btn" @click="tocenter(item)">立即学习</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 新手入门 -->
        <div class="tuijian-list-kecheng">
          <div class="tuijian-kecheng-tuijiankc">
            <div class="biduhuize">新手入门</div>
            <div><span style="margin-right: 5px;color:#275AF5;font-size: 15px;" @click="tokecehgn()">更多课程</span><img style="width: 10px;height: 10px;" src="../assets/image/lanjiantou.png"/></div>
          </div>
          <div class="tuijian-kecheng-xuxilist">
            <div class="tuijian-xuxilist-list" :class="(index + 1) % 4  == 0 ? 'mele':''" v-for="(item,index) in xrenlist" :key="index">
              <div class="tuijian-xuanlist-img"><img  :src="item.img"/></div>
              <div class="tuijian-xuanlist-biaoti">{{item.title}}</div>
              <div class="kanshu-anniu-lost">
                <div class="tuijian-renshu-zong">
                  <div><img style=" width: 14px;height: 14px;margin-right: 5px;" src="../assets/image/huiyan.png"/></div>
                  <div>{{item.browse_number}}</div>
                </div>
                <div class="kanshu-anniu-btn" @click="tocenter(item)">立即学习</div>
              </div>
            </div>
          </div>
        </div>
         <!-- 成功案例 -->
        <div class="tuijian-list-kecheng-anli">
          <div class="tuijian-kecheng-tuijiankc">
            <div class="biduhuize">成功案例</div>
            <div><span style="margin-right: 5px;color:#275AF5;font-size: 15px;"  @click="tokecehgn()">更多课程</span><img style="width: 10px;height: 10px;" src="../assets/image/lanjiantou.png"/></div>
          </div>
          <div class="tuijian-kecheng-xuxilist">
            <div class="tuijian-xuxilist-anlisr" :class="(index + 1) % 3  == 0 ? 'meleanli':''" v-for="(item,index) in seccan" :key="index">
              <div class="tuijian-xuanlist-anli-img"><img  :src="item.img"/></div>
              <div class="tuijian-xuanlist-biaoti">{{item.title}}</div>
              <div class="kanshu-anniu-lost-ll">
                <div class="tuijian-renshu-zong">
                  <div><img style=" width: 14px;height: 14px;margin-right: 5px;" src="../assets/image/huiyan.png"/></div>
                  <div>{{item.browse_number}}</div>
                </div>
                <div class="kanshu-anniu-btn" @click="tocenter(item)">立即学习</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 功能伤心-最新活动 -->
        <div class="home-gongnnen-shangxin">
          <div class="home-gongnnen-shangxin-zomg">
              <div class="hone-gongshang">
                <div class="hondfgd-lisrt">
                  <div class="hone-lisrt-ziti">功能上新</div>
                  <div class="homg-zuolist">
                    <img style="width: 10px;height: 10px;" v-if="featuts.page==1" src="../assets/image/huijianzuo.png"/>
                    <img style="width: 10px;height: 10px;" v-else @click="pagelisd(false)" src="../assets/image/lanjiantouleft.png"/>
                    <div class="shuzi-num">{{featuts.page}}/{{total}}</div>
                    <img style="width: 10px;height: 10px;" v-if="featuts.page==total" src="../assets/image/huijiantouyou.png"/>
                    <img style="width: 10px;height: 10px;" v-else @click="pagelisd(true)" src="../assets/image/lanjiantou.png"/>
                  </div>
                </div>
                <div class="jomlis-zuoy-tiiti" v-for="(item,index) in gnshangxin" :key="index" @click="zuixingn(item)">
                  <div>{{item.title}}</div>
                  <div>{{item.send_time}}</div>
                </div>
              </div>
            
            <div class="hone-gongshang">
              <div class="hondfgd-lisrt">
                  <div class="hone-lisrt-ziti">最新活动</div>
                  <div class="homg-zuolist">
                    <img style="width: 10px;height: 10px;" v-if="featutsodd.page==1" src="../assets/image/huijianzuo.png"/>
                    <img style="width: 10px;height: 10px;" v-else @click="pagelisdidd(false)" src="../assets/image/lanjiantouleft.png"/>
                    <div class="shuzi-num">{{featutsodd.page}}/{{totalod}}</div>
                    <img style="width: 10px;height: 10px;" v-if="featutsodd.page==totalod" src="../assets/image/huijiantouyou.png"/>
                    <img style="width: 10px;height: 10px;" v-else @click="pagelisdidd(true)" src="../assets/image/lanjiantou.png"/>
                  </div>
                </div>
                <div class="jomlis-zuoy-tiiti" v-for="(item,index) in newhd" :key="index" @click="zuixinhuod(item)">
                  <div>{{item.title}}</div>
                  <div>{{item.createtime}}</div>
                </div>
            </div>
          </div>
        </div>
        <!-- 关于小邦 -->
        <div class="home-guanyuxiaobang-list">
          <div class="biduhuize">关于小邦</div>
          <div class="home-guanyuxiaobang-list-imgkhg">
            <div class="guanyuxiaobanng-list-hone" :class="(index + 1) % 4  == 0 ? 'meleanli':''" v-for="(item,index) in Xiaobanglist" :key="index">
                <div class="neirong-list">
                  <div class="tititiitit">NowThis</div>
                  <div class="xiaobang-neirong">{{item.title}}</div>
                  <div v-if="item.link==''||item.link==null">
                    <!-- <el-tooltip class="item" effect="dark" placement="bottom-start">
                      <div slot="content">
                        <img style="width: 103px;height: 103px;" :src="item.img" alt="Tooltip Image" />
                      </div>
                      <div class="guanli-btn-lifs">
                        <div style="margin-right: 5px;">去了解</div>
                        <img style="width: 12px; height: 12px;" src="../assets/image/baisejiantou.png"/>
                      </div>
                    </el-tooltip> -->
                      <div v-if="imgfile==true"  @click="autobtn(item)">
                        <div class="climg-listgg">
                          <img style="width: 153px;height: 153px;" :src="item.img" alt="Tooltip Image" />
                        </div>
                      </div>
                      <div class="guanli-btn-lifs" v-if="imgfile==false"  @click="autobtn(item)">
                        <div style="margin-right: 5px;">去了解</div>
                        <img style="width: 12px; height: 12px;" src="../assets/image/baisejiantou.png"/>
                      </div>
                  </div>
                  <div v-if="item.img==''||item.img==null">
                    <div class="guanli-btn-lifs" @click="toliaojei(item)">
                        <div style="margin-right: 5px;">去了解</div>
                        <img style="width: 12px; height: 12px;" src="../assets/image/baisejiantou.png"/>
                      </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
    </div>
    <div class="liuyan-list">
      <div class="liuyan-tu" @click="tankuang()">
        <img src="../assets/image/liuyantu.png"/>
        <div>给我留言</div>
      </div>
    </div>
    <div>
      <el-dialog
        title="给我留言"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <div class="liuyan-shuru">
          <el-form ref="form" :model="form" :rules="rules" label-width="110px">
             <el-form-item label="注：">
                <div><span style="color:#FF0000;">*</span>号为必填项</div>
              </el-form-item>
              <el-form-item label="姓名：">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="手机：">
                <el-input v-model="form.phone"></el-input>
              </el-form-item>
              <el-form-item label="留言内容：" prop="desc">
                <el-input type="textarea" v-model="form.desc" maxlength="150" show-word-limit></el-input>
              </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button  @click="czbtn()">重 置</el-button>
          <el-button type="primary" @click="submit()">提 交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  export default {
    name:"index",
    data () {
      return {
        imgfile:false,
        videokey:100,
        bannerlist:[],
        swiper:0,
        totop:false,
        time:null,
        dialogVisible:false,
        form:{
          name:'',
          phone:'',
          desc:''
        },
         rules: {
          desc: [
            { required: true, message: '请填写留言内容', trigger: 'blur' }
          ]
        },
        reildlist:{
          page:1,
          limit:10,
          rule_center_id:"0",
          type:"1"
        },
        xuanlist:{
          page:1,
          limit:12,
          industry_course_center_id:'0',
          course_course_center_id:'0',
          dev_course_center_id:'0',
          order_set:'2'
        },
        xsrmenlist:{
          page:1,
          limit:10,
          industry_course_center_id:'0',
          course_course_center_id:'0',
          dev_course_center_id:'11',
          order_set:'0'
        },
        seccanlist:{
          page:1,
          limit:10,
          industry_course_center_id:'0',
          course_course_center_id:'7',
          dev_course_center_id:'0',
          order_set:'0'
        },
        featuts:{
          page:1,
          limit:10,
        },
        featutsodd:{
           page:1,
          limit:10,
        },
        total:0,
        totalod:0,
        reild:[],
        keclist:[],
        xrenlist:[],
        seccan:[],
        gnshangxin:[],
        newhd:[],
        Xiaobanglist:[]
      }
    }, 
    // watch:{
    //   $route:{
    //     handler(val,oldval){
    //       if(val.query.solution == 'true'){
    //         let tops;
    //         if($(window).width()<= 1300){
    //           tops = 1575
    //         }else if($(window).width()>1330 && $(window).width()<=1407){
    //           tops = 1617
    //         }else if($(window).width()>1407 && $(window).width()<= 1543){
    //           tops = 1699
    //         }else if($(window).width()>1543){
    //           tops = 1930
    //         }

    //         $('body,html').animate({
    //           scrollTop: tops
    //         },200);
    //         this.$router.push('/')
    //       }
    //     },
    //     deep: true
    //   }
    // },
    destroyed(){
      clearInterval(this.time)
    },
    mounted () {
      let left = 0
      let unm = 0
      this.time = setInterval(()=>{
        if(unm%10 == 0){
          // console.log(unm)
          let ac = unm/10
          $('.gun').append(`<img style="width:auto;height:67.31px;margin-left:103px;" src="${require('../assets/m'+ ((ac%12)+1) +'.png')}" alt="">`)
        }
        $('.gun').css('left',-(left+=5))
        unm++
      },100)

      if(this.$route.query.solution == 'true'){
        let tops;
        if($(window).width()<= 1330){
          tops = 1575
        }else if($(window).width()>1330 && $(window).width()<=1407){
          tops = 1617
        }else if($(window).width()>1407 && $(window).width()<= 1543){
          tops = 1699
        }else if($(window).width()>1543){
          tops = 1930
        }
        $('body,html').animate({
          scrollTop: tops
        },200);
        this.$router.push('/')
      }
      let that = this
      $(window).scroll(function(){
        var topp = $(document).scrollTop();
        // console.log(topp)
          if(topp > 647){
            that.totop = true
          }else{
            that.totop = false
          }
      })
    },
    created() {
      this.Requiredlist()
      this.keczxlist()
      this.xsrmlist()
      this.cganllist()
      this.Newfeatures()
      this.newhuodong()
      this.AboutXiaobang()
      this.getconfiglist()
    },
    methods: {
      // 点击了解按钮
      autobtn(item){
        console.log(111)
        this.imgfile = !this.imgfile
        // if(item.img!=''){
        //   this.imgfile = true
        // }else{
        //   this.imgfile = false
        // }
      },
      //
      bannerchange(){
        this.videokey += 1
      },
      //跳转页面
      gotopage(url){
        window.open(url)
      },
      //获取首页信息
      getconfiglist(){
        let that = this
        that.$http({
          url: that.baseurl +"/getApiBanner",
          method: "GET",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
        })
          .then(res => {
            console.log(res.data)
              // that.bannerlist = JSON.parse(res.data.data.filter(_=>_.id == 49)[0].value) || []
              that.bannerlist = res.data.data
              console.log(that.bannerlist[0].url[0].url
,'url')
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      // 更多课程
      tokecehgn(){
        this.$router.push('/about')
      },
      // 立即学习
      tocenter(data){
        console.log(data,'$$$$$')
        this.$router.push('/aboutdetails?id='+data.id)
      },
      czbtn(){
        this.form.name='';
        this.form.desc='';
        this.form.phone=''
      },
      // 留言提交
      submit(){
        let that = this
        if(this.form.desc==""){
          return that.$message.warning('请输入留言内容');
        }
        that.$http({
          url: that.baseurl +"/doFeedback",
          method: "post",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{
            name:this.form.name,
            details:this.form.desc,
            mobile:this.form.phone
          }
        })
          .then(res => {
              if(res.data.code==200){
                  that.$message.success(res.data.data);
                  that.dialogVisible = false
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      // 必读规则列表
      Requiredlist(){
        let that = this
        that.$http({
          url: that.baseurl +"/getRuleIndex?page="+ that.reildlist.page +'&limit='+that.reildlist.limit+'&rule_center_id='+that.reildlist.rule_center_id+'&type='+that.reildlist.type,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  this.reild = res.data.data.data
                  // that.$message.success(res.data.msg);
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      // 更多规则中心
      toguize(){
        this.$router.push('/case')
      },
      // 更多规则详情
      bidugz(data){
        this.$router.push('/ReadingRules?id='+data.id)
      },
      // 课程中心
      keczxlist(){
        let that = this
        that.$http({
          url: that.baseurl +"/getCourseIndex?page="+ that.xuanlist.page +'&limit='+that.xuanlist.limit+'&industry_course_center_id='+that.xuanlist.industry_course_center_id+'&course_course_center_id='+that.xuanlist.course_course_center_id+'&dev_course_center_id='+that.xuanlist.dev_course_center_id+'&order_set='+that.xuanlist.order_set,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  this.keclist = res.data.data.data.slice(0, 12);
                  // that.$message.success(res.data.msg);
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      // 新手入门
      xsrmlist(){
        let that = this
        that.$http({
          url: that.baseurl +"/getCourseIndex?page="+ that.xsrmenlist.page +'&limit='+that.xsrmenlist.limit+'&industry_course_center_id='+that.xsrmenlist.industry_course_center_id+'&course_course_center_id='+that.xsrmenlist.course_course_center_id+'&dev_course_center_id='+that.xsrmenlist.dev_course_center_id+'&order_set='+that.xsrmenlist.order_set,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  this.xrenlist = res.data.data.data.slice(0, 8);
                  // that.$message.success(res.data.msg);
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      // 成功案例
      cganllist(){
        let that = this
        that.$http({
          url: that.baseurl +"/getCourseIndex?page="+ that.seccanlist.page +'&limit='+that.seccanlist.limit+'&industry_course_center_id='+that.seccanlist.industry_course_center_id+'&course_course_center_id='+that.seccanlist.course_course_center_id+'&dev_course_center_id='+that.seccanlist.dev_course_center_id+'&order_set='+that.seccanlist.order_set,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  this.seccan = res.data.data.data.slice(0, 3);
                  // that.$message.success(res.data.msg);
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      pagelisd(e){
        if(e){
          this.featuts.page++;
          this.Newfeatures()
        }else{
          this.featuts.page--;
          this.Newfeatures()
        }
      },
      pagelisdidd(e){
        if(e){
          this.featutsodd.page++;
          this.newhuodong()
        }else{
          this.featutsodd.page--;
          this.newhuodong()
        }
      },
      // 功能上新
      Newfeatures(){
        let that = this
        that.$http({
          url: that.baseurl +"/getNewFeaturesIndex?page="+ that.featuts.page +'&limit='+that.featuts.limit,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  that.total = res.data.data.last_page
                  that.gnshangxin = res.data.data.data
                  // that.$message.success(res.data.msg);
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      // 最新活动
      newhuodong(){
        let that = this
        that.$http({
          url: that.baseurl +"/getActivityCenterIndex?page="+ that.featutsodd.page +'&limit='+that.featutsodd.limit,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  that.totalod = res.data.data.last_page
                  that.newhd = res.data.data.data
                  // that.$message.success(res.data.msg);
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      // 关于小邦
      AboutXiaobang(){
        let that = this
        that.$http({
          url: that.baseurl +"/getAboutIndex?page="+ that.featuts.page +'&limit='+that.featuts.limit,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => { 
              if(res.data.code==200){
                  that.Xiaobanglist = res.data.data.data.slice(0, 4);
                  // that.$message.success(res.data.msg);
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      toliaojei(item){
        console.log(item.link,'@@@@@')
        window.open(item.link)
        // window.location.href = 'https://www.example.com';
      },
      // 留言弹框
      handleClose(){
        this.dialogVisible = false
      },
      // 留言弹框
      tankuang(){
        this.dialogVisible = true
        this.form.name='';
        this.form.desc='';
        this.form.phone=''
      },
      zuixingn(data){
        this.$router.push('/Latestfeatures?id='+data.id)
      },
      zuixinhuod(data){
         this.$router.push('/LatestActivities?id='+data.id)
      },
      jump(e){
        this.$router.push('/info')
      },
      selewei(e){
        this.swiper = e
      },
      seletop(){
        $('body,html').animate({
          scrollTop: 0
        },200);
      },
      toptop(){
        let tops;
        if($(window).width()<= 1330){
          tops = 1575
        }else if($(window).width()>1330 && $(window).width()<=1407){
          tops = 1617
        }else if($(window).width()>1407 && $(window).width()<= 1543){
          tops = 1699
        }else if($(window).width()>1543){
          tops = 1930
        }
        $('body,html').animate({
          scrollTop: tops
        },200);
      },
    },
  }

</script>
<style scoped>
  .home{
    width: 100%;
    background-color: #F2F5FB ;
    position: relative;
  }
  .liuyan-list{
    position: fixed;
    top: 70%;
    right: 0;
    background-color: #275AF5;
    width: 64px;
    height: 64px;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
   
  }
  .liuyan-tu{
     padding: 5px 0;
  }
  .liuyan-list img{
    width: 28px;
    height: 28px;
    /* padding-bottom: 5px; */
  }
  .home-bnddr{
    width: 100%;
    max-width: 1520px;
    /* height: 500px; */
    margin: 0px auto;
     
  }
  .honme-benerr-list{
    width: 100%;
    height: 590px;
    display: flex;
    justify-content: space-between;
    padding: 87px 0 20px 0;
  }
  .honme-benerr-list-img{
    border-radius: 8px;
    width: 69%;
  }
  .honme-benerr-list-tiaomu{
    width: 30%;
    border-radius: 8px;
    background-color: #FFFFFF;
  }
  .honme-benerr-list-tiaomu-title{
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid #EEEEEE;
  }
  .honme-benerr-title-baioti{
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: space-between;
  }
  .biduhuize{
    color:#333333;
    font-size: 18px;
    font-weight:550;
  }
  .home-biduguize{
    width: 82%;
    margin-left: 9%;
    margin-right: 9%;
    color: #333333;
    font-size: 14px;
    display: flex;
    margin-top: 13px;
    position: relative;
  }
  .home-biduguize-list::after{
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color:#275AF5;
    opacity: 0.5;
    position: absolute;
    left: -12px;
    top: 8px;
  }
  .tuijian-list-kecheng{
    width: 100%;
    /* height: 900px; */
    /* background-color:#99a9bf; */
    margin-top: 30px;
    padding-bottom: 25px;
  }
  .tuijian-kecheng-tuijiankc{
    display: flex;
    justify-content: space-between;
  }
  .tuijian-kecheng-xuxilist{
    width: 100%;
  }
  
  .tuijian-xuxilist-list{
    width:calc(25% - 15px);
    display: inline-block;
    position: relative;
    height: 326px;
    /* height: 326px; */
    background-color:#FFFFFF ;
    border-radius: 8px 8px 0 0;
    margin-top: 20px;
    margin-right: 20px;
  }
  
  .mele{
    margin-right: 0;
  }
  .tuijian-xuanlist-img{
    width: 100%;
    border-radius: 8px 8px 0 0;
    background-color:  #d3dce6;
  }
  .tuijian-xuanlist-img img{
    width: 100%;
    height: 216px;
    border-radius: 8px 8px 0 0;
  }
  .tuijian-list-kecheng-anli{
     width: 100%;
    /* height: 900px; */
    /* background-color:#99a9bf; */
    margin-top: 30px;
    padding-bottom: 25px;
  }
  .tuijian-xuxilist-anlisr{
    width:calc(33.33% - 13.33px);
    display: inline-block;
    position: relative;
    height: 326px;
    background-color:#FFFFFF ;
    border-radius: 8px 8px 0 0;
    margin-top: 20px;
    margin-right: 20px;
  }
  
  .tuijian-xuanlist-anli-img{
    width: 100%;
    border-radius: 8px 8px 0 0;
     background-color:  #d3dce6;
  }
  .tuijian-xuanlist-anli-img img{
    width: 100%;
    height: 216px;
    border-radius: 8px 8px 0 0;
  }
  .tuijian-xuanlist-biaoti{
    font-size: 16px;
    color: #333333;
    font-weight: 550;
    padding: 16px 16px;
  }
  .kanshu-anniu-lost{
    display: flex;
    justify-content: space-between;
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    /* margin-top: 15px; */
  }
  .kanshu-anniu-lost-ll{
    display: flex;
    justify-content: space-between;
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
  }
  .tuijian-renshu-zong{
    display: flex;
    align-items: center;
    color: #999999;
    font-size: 14px;
  }
  .kanshu-anniu-btn{
    width: 96px;
    height: 32px;
    line-height: 32px;
    background-color: #275AF5;
    border-radius: 4px;
    color:#FFFFFF;
    font-size: 16px;
    text-align: center;
  }
  .home-gongnnen-shangxin{
    width: 100%;
    margin-top: 30px;
    padding-bottom: 25px;
  }
  .home-gongnnen-shangxin-zomg{
      display: flex;
      justify-content: space-between;
  }
  .hone-gongshang{
    width: 49%;
    height: 505px;
    background-color: #fff;
    border-radius: 8px;
  }
  .hondfgd-lisrt{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #EEEEEE;
  }
  .hone-lisrt-ziti{
    font-size: 24px;
    color: #333333;
    font-weight: 550;
  }
  .homg-zuolist{
    width: 89px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    background-color: #F2F5FB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }
  .shuzi-num{
    font-size: 16px;
    color: #333333;
  }
  .jomlis-zuoy-tiiti{
    display: flex;
    justify-content: space-between;
    padding: 9px 20px;
  }
  .jomlis-zuoy-tiiti div:nth-child(1){
    font-weight: 500;
    font-size: 16px;
    color: #333333;
  }
  .jomlis-zuoy-tiiti div:nth-child(2){
   font-weight: 400;
    font-size: 14px;
    color: #999999;
  }
  .home-guanyuxiaobang-list{
    margin-top: 20px;
    padding-bottom: 26px;
  }
  .home-guanyuxiaobang-list-imgkhg{
    width: 100%;
    /* text-align: center; */
  }
  .guanyuxiaobanng-list-hone{
    width:calc(25% - 15px);
    display: inline-block;
    overflow: hidden;
    position: relative;
    height: 403px;
    line-height: 60px;
    background: url('../assets/image/guanyuxioabang_01.png') no-repeat;
    background-size: cover;
    /* background-color:#FFFFFF ; */
    margin-top: 20px;
    margin-right: 20px;
    text-align: center;
  }
  .neirong-list{
    margin-top: 110px;
  }
  .tititiitit{
    font-size: 22px;
    color: #333333;
    font-weight: 550;
  }
  .xiaobang-neirong{
    font-size: 18px;
    color: #666666;
    font-weight: 400;
    /* margin-top: 80px; */
    padding-bottom:7px;
  }
  .home-guanyuxiaobang-list-imgkhg >.guanyuxiaobanng-list-hone:nth-child(2){
    background: url('../assets/image/guanyuxioabnang_02.png') no-repeat;
    background-size: cover;
  }
  .home-guanyuxiaobang-list-imgkhg >.guanyuxiaobanng-list-hone:nth-child(3){
    background: url('../assets/image/guanyuxiaobang_03.png') no-repeat;
    background-size: cover;
  }
  .home-guanyuxiaobang-list-imgkhg >.guanyuxiaobanng-list-hone:nth-child(4){
    background: url('../assets/image/guanyuxiaobang_04.png') no-repeat;
    background-size: cover;
  }
  .guanli-btn-lifs{
    width: 104px;
    height: 36px;
    background-color: #275AF5;
    border-radius: 4px;
    border: 1px solid #275AF5;
    color: #FFFFFF;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:20px auto;
    
  }
  .climg-listgg{
    width:153px;
    height: 153px;
    text-align: center;
    margin: 0 auto;
    background-color: antiquewhite;
  }
  .meleanli{
    margin-right: 0;
  }
  .el-carousel__item{
    border-radius: 8px;
    
  }
  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
     border-radius: 8px;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
     border-radius: 8px;
  }
  .liuyan-shuru{
    width: 100%;
    margin: 0 auto; 
  }
</style>
<style>
.liuyan-shuru .el-input{
  width: 90%;
}
.liuyan-shuru .el-textarea__inner{
  width: 90%;
  height: 104px;
}
.liuyan-shuru .el-input__count{
    position: absolute;
    bottom: 3px;
    right: 70px;
}
.el-dialog {
    border-radius: 8px;
}

.banner-img{
  width: 100%;
  height: 100%;
  display: block;
}
.banner-video{
  width: 100%;
  height: 100%;
  display: block;
}
</style>
